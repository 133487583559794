import React  from "react";
import { Link } from "gatsby"

import SolutionList from "../components/solutions/list";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../components/contact";
import Office from "../components/office";

const ServicesPage = () => {
    return (
        <Layout>
            <SEO title={`Blog`} />

            <div className="o-container">
                <h1 className="u-h1 u-c o-heading">Our <strong>blog</strong></h1>

                <p className="u-l u-mb--xxl">Get inspired with the latest tech trends. Launching soon.</p>

                <Contact />
                <Office/>
            </div>
        </Layout>
    );
};

export default ServicesPage;
